import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
export const Helper = (props) => (
    <Card>
        <Title title="Helper" />
        <CardContent>
            Postman API Key
            <button onClick={() => { navigator.clipboard.writeText('PMAK-62392f3038b29576ad0759d1-ee33013fc6c810c7cdad58d8e42948b919') }}>Copy</button>
        </CardContent>
        <hr />
        <CardContent>
            ABN Lookup GUID Key
            <button onClick={() => { navigator.clipboard.writeText('7c7d5089-f33a-4f11-9e17-fd226585817e') }}>Copy</button>
        </CardContent>
        <hr/>
        <CardContent>
            Sandbox Creds for Billing
            <ul>
                <li>Stripe</li>
                <ul>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('pk_test_mG0KFyDRwQMgvJzzhP05Xc3e005dqYsB4M') }}>Copy Publishable key</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('sk_test_i2hkrvs1sjqXxjMKRZGAGkZQ00iJbuPgkw') }}>Copy Secret key</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('prod_JrtnF79K05L6yn') }}>Copy One cent product id</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('prod_I1xIE0Gy1bOTgR') }}>Copy Retailer subscription model</button>
                    </li>
                </ul>
                <li>PayPal</li>
                <ul>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('John') }}>Copy First name</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('Doe') }}>Copy Last name</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('sb-47j7dy2617748@business.example.com') }}>Copy Email ID</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('Ws3<K8#y') }}>Copy System Generated Password</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('sb-uzij62630628@personal.example.com') }}>Copy PayPal personal sandbox account - email</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('D@je*I3i') }}>Copy PayPal personal sandbox account - password</button>
                    </li>

                </ul>
                <li>NVP / SOAP Sandbox API Credentials</li>
                <ul>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('sb-47j7dy2617748_api1.business.example.com<') }}>Copy Username</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('8PPJBHG5KBEBD4BN') }}>Copy Password</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('AVK43LzNAYwgrzbmGaevgC8Zh3gSAgQ7pThv4DEurT8NTvwqUSYgk-0d') }}>Copy Signature</button>
                    </li>
                </ul>
                <li>
                    <button onClick={() => { navigator.clipboard.writeText('whsec_S6zxRAZMpPYSa40mvNRHPhmf892xp9RO') }}>Copy Webhook Signing secret for biller listener</button>
                </li>
            </ul>
        </CardContent>
        <hr />
        <CardContent>
            <ul>
                <li>Apple Keys</li>
                <ul>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('com.shahryarfaraji.1Receipt') }}>Copy Apple_1Receipt_Identifier</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('com.quadcore.1Receipt') }}>Copy AppleOAuth_Client_Id</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgN4RvOaKhpmPh+1+Iyp2Et3w984t/ZkYZvI0YBS04elegCgYIKoZIzj0DAQehRANCAARDzP/G9uSepPKbCmuzK1KuhOL2QjxTc6zheTzHpjJEL24VZTzwZcB2RyniWwa5/FLdRnL5CxRR6SLlTFPCfbah') }}>Copy AppleOAuth_Private_Key</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('1r14463646') }}>Copy ApplePass_Serial_Number</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('pass.com.1receipt.1receipt-card') }}>Copy Apple_Pass_Identifier</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('1340659825') }}>Copy App_Store_Identifier</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('4426') }}>Copy Apple_Pass_Certificate_Password</button>
                    </li>
                </ul>
                <li>Google Keys</li>
                <ul>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('334532324543-tgjps64vmo6ucsb2dvagdqhsqi944bkc.apps.googleusercontent.com') }}>Copy GoogleOAuth_Client_Id</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('Duc0uQMGKvjXi5vWGR8LzaZm') }}>Copy GoogleOAuth_Client_Secret</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('carbide-theme-201105') }}>Copy GoogleOAuth_Project_Id</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('receipt-gpass@carbide-theme-201105.iam.gserviceaccount.com') }}>Copy GooglePass_Email_Address</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('3368842603526136141') }}>Copy GooglePass_Issuer_Id</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('mono.x1receipt.user') }}>Copy Google_Play_Identifier</button>
                    </li>
                    <li>
                        <button onClick={() => { navigator.clipboard.writeText('AAAATeOuLL8:APA91bFk4eEF9O3138iVDd8VD0HM5u2tmSp8Aepd045XIsNtyNEmwCi4kUn9-zKhe2LFSQLAtMD1U3-yopChBq7c82k2gIlMSJm6Euc_OWuMeJxtEQMIwzd-Va7lI_toUC34bRT9pH0x') }}>Copy FCM Server Key</button>
                    </li>
                </ul>
            </ul>
        </CardContent>
        <hr />
        <CardContent>
            <ul>
                <li>Authorized Auth Domains and Subdomains</li>
                <ul>
                    <li>
                        sandbox-auth.1receipt.io
                    </li>
                    <li>
                        auth.1receipt.io
                    </li>
                    <li>
                        shopper-sandbox-auth.1receipt.io
                    </li>
                    <li>
                        1receipt-shopper-sandbox.auth.ap-southeast-2.amazoncognito.com
                    </li>
                    <li>
                        shopper-auth.1receipt.io
                    </li>
                    <li>
                        1receipt-shopper.auth.ap-southeast-2.amazoncognito.com
                    </li>
                </ul>
            </ul>
            <ul>
                <li>Authorized Auth Return URLs</li>
                <ul>
                    <li>
                        https://shopper-sandbox-auth.1receipt.io/oauth2/idpresponse
                    </li>
                    <li>
                        https://1receipt-shopper-sandbox.auth.ap-southeast-2.amazoncognito.com/oauth2/idpresponse
                    </li>
                    <li>
                        https://shopper-auth.1receipt.io/oauth2/idpresponse
                    </li>
                    <li>
                        https://1receipt-shopper.auth.ap-southeast-2.amazoncognito.com/oauth2/idpresponse
                    </li>
                </ul>
            </ul>
        </CardContent>
        <hr />
        <CardContent>
            <ul>
                <li>NODE.JS Environment Varables</li>
                <ul>
                    <li><b>Local / Stage</b></li>
                    <ul class="list-unstyled">
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export ENV_NAME=local') }}>Copy Local ENV_NAME</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export TEST=Test-') }}>Copy TEST</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export TIMER_ON=true') }}>Copy TIMER_ON</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export PORT=5000') }}>Copy Local PORT</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export DOMAIN=http://localhost:5000') }}>Copy Local DOMAIN</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export ENV_NAME=stage') }}>Copy Stage ENV_NAME</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export PORT=5000') }}>Copy Stage PORT</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export DOMAIN=https://nodejs-api-stage.1receipt.io') }}>Copy Stage DOMAIN</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_ACCESS_KEY_ID=AKIASMWMDYZNCB4FJ3TB') }}>Copy AWS_ACCESS_KEY_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_SECRET_ACCESS_KEY=Qkz+E9kUDiAlTwOs72Vv28jZmVbgCrzB/VqRUwnd') }}>Copy AWS_SECRET_ACCESS_KEY</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_RETAILER=ap-southeast-2_Ql24FMdsy') }}>Copy AWS_USERPOOL_RETAILER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_SHOPPER=ap-southeast-2_p3lTm2qe9') }}>Copy AWS_USERPOOL_SHOPPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_ADMIN=ap-southeast-2_iTEnlHbkk') }}>Copy AWS_USERPOOL_ADMIN</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_DEVELOPER=ap-southeast-2_Bn6dUeH4A') }}>Copy AWS_USERPOOL_DEVELOPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_RETAILER=loc6k58c5hocntnp2vh1cs5dar8jkal') }}>Copy AWS_COGNITO_CLIENT_ID_RETAILER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_CONSOLE=107gne5rl0ok6obgksvkbpkv2n') }}>Copy AWS_COGNITO_CLIENT_ID_CONSOLE</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_WINAPP=5jh2ok0q5cjqumhqphuismf3qp') }}>Copy AWS_COGNITO_CLIENT_ID_WINAPP</button>
                        </li>
                        <li> 
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_SHOPPER=3a36b6ik43n1dl91s8nsqs0cu7') }}>Copy AWS_COGNITO_CLIENT_ID_SHOPPER</button>
                        </li>
                        <li> 
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_DEVELOPER=7phkfadlfo1ctksa95pek6fh53') }}>Copy AWS_COGNITO_CLIENT_ID_DEVELOPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_ADMIN_SUB=ee05e8ee-e89a-4475-99de-61e5773219d9') }}>Copy AWS_COGNITO_ADMIN_SUB</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_PROJECT_ID=234c6410b27846828d9562bc59818ce4') }}>Copy AWS_PINPOINT_PROJECT_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_EMAIL_PROJECT_ID=e14181b8b6dc4441a8b54c4cc3b1092f') }}>Copy AWS_PINPOINT_EMAIL_PROJECT_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_EVENT_BUCKET=pinpoint-email-events-ks') }}>Copy AWS_PINPOINT_EVENT_BUCKET</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_SENDER=info@1receipt.io') }}>Copy AWS_PINPOINT_SENDER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_SECRET_KEY=sk_test_i2hkrvs1sjqXxjMKRZGAGkZQ00iJbuPgkw') }}>Copy STRIPE_SECRET_KEY</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_TAX_RATE_ID=txr_1HUS3gC2eoMEKKLBnfg8NJdR') }}>Copy STRIPE_TAX_RATE_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_ONE_CENT_PROD_ID=prod_JrtnF79K05L6yn') }}>Copy STRIPE_ONE_CENT_PROD_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export S3_APPLE_CERTIFICATE_PASSWORD=4426') }}>Copy S3_APPLE_CERTIFICATE_PASSWORD</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export APPLE_TEAM_IDENTIFIER=5P22GV9PRB') }}>Copy APPLE_TEAM_IDENTIFIER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export APPLE_SERIAL_NUMBER=1r14463646') }}>Copy APPLE_SERIAL_NUMBER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export GOOGLE_PASS_ISSUER_ID=3368842603526136141') }}>Copy GOOGLE_PASS_ISSUER_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export GOOGLE_PASS_EMAIL_ADDRESS=receipt-gpass@carbide-theme-201105.iam.gserviceaccount.com') }}>Copy GOOGLE_PASS_EMAIL_ADDRESS</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export ABN_VALIDATION_GUID=7c7d5089-f33a-4f11-9e17-fd226585817e') }}>Copy ABN_VALIDATION_GUID</button>
                        </li>
                    </ul>
                    <li><b>Prod</b></li>
                    <ul class="list-unstyled">
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export ENV_NAME=prod') }}>Copy ENV_NAME</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export TEST=') }}>Copy TEST</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export TIMER_ON=true') }}>Copy TIMER_ON</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export PORT=5000') }}>Copy PORT</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export DOMAIN=https://nodejs-api.1receipt.io') }}>Copy DOMAIN</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_ACCESS_KEY_ID=AKIASMWMDYZNCB4FJ3TB') }}>Copy AWS_ACCESS_KEY_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_SECRET_ACCESS_KEY=Qkz+E9kUDiAlTwOs72Vv28jZmVbgCrzB/VqRUwnd') }}>Copy AWS_SECRET_ACCESS_KEY</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_RETAILER=ap-southeast-2_UzfrdyR9s') }}>Copy AWS_USERPOOL_RETAILER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_SHOPPER=ap-southeast-2_j1aimrjgy') }}>Copy AWS_USERPOOL_SHOPPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_ADMIN=ap-southeast-2_iTEnlHbkk') }}>Copy AWS_USERPOOL_ADMIN</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_USERPOOL_DEVELOPER=ap-southeast-2_Bn6dUeH4A') }}>Copy AWS_USERPOOL_DEVELOPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_RETAILER=5lk71r515k9f6gquce8ev96580') }}>Copy AWS_COGNITO_CLIENT_ID_RETAILER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_CONSOLE=107gne5rl0ok6obgksvkbpkv2n') }}>Copy AWS_COGNITO_CLIENT_ID_CONSOLE</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_WINAPP=58nmhgaakjacfdl35jaai8cj4t') }}>Copy AWS_COGNITO_CLIENT_ID_WINAPP</button>
                        </li>
                        <li> 
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_SHOPPER=21jhrjir8hol2r769c21i386as') }}>Copy AWS_COGNITO_CLIENT_ID_SHOPPER</button>
                        </li>
                        <li> 
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_CLIENT_ID_DEVELOPER=7phkfadlfo1ctksa95pek6fh53') }}>Copy AWS_COGNITO_CLIENT_ID_DEVELOPER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_COGNITO_ADMIN_SUB=ee05e8ee-e89a-4475-99de-61e5773219d9') }}>Copy AWS_COGNITO_ADMIN_SUB</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_PROJECT_ID=234c6410b27846828d9562bc59818ce4') }}>Copy AWS_PINPOINT_PROJECT_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_EMAIL_PROJECT_ID=e14181b8b6dc4441a8b54c4cc3b1092f') }}>Copy AWS_PINPOINT_EMAIL_PROJECT_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_EVENT_BUCKET=pinpoint-email-events-ks') }}>Copy AWS_PINPOINT_EVENT_BUCKET</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export AWS_PINPOINT_SENDER=info@1receipt.io') }}>Copy AWS_PINPOINT_SENDER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_SECRET_KEY=sk_test_i2hkrvs1sjqXxjMKRZGAGkZQ00iJbuPgkw') }}>Copy STRIPE_SECRET_KEY</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_TAX_RATE_ID=txr_1HUS42C2eoMEKKLBXFNCyR3t') }}>Copy STRIPE_TAX_RATE_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export STRIPE_ONE_CENT_PROD_ID=prod_JszKLm9VXxqgDh') }}>Copy STRIPE_ONE_CENT_PROD_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export S3_APPLE_CERTIFICATE_PASSWORD=4426') }}>Copy S3_APPLE_CERTIFICATE_PASSWORD</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export APPLE_TEAM_IDENTIFIER=5P22GV9PRB') }}>Copy APPLE_TEAM_IDENTIFIER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export APPLE_SERIAL_NUMBER=1r14463646') }}>Copy APPLE_SERIAL_NUMBER</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export GOOGLE_PASS_ISSUER_ID=3368842603526136141') }}>Copy GOOGLE_PASS_ISSUER_ID</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export GOOGLE_PASS_EMAIL_ADDRESS=receipt-gpass@carbide-theme-201105.iam.gserviceaccount.com') }}>Copy GOOGLE_PASS_EMAIL_ADDRESS</button>
                        </li>
                        <li>
                            <button onClick={() => { navigator.clipboard.writeText('export ABN_VALIDATION_GUID=7c7d5089-f33a-4f11-9e17-fd226585817e') }}>Copy ABN_VALIDATION_GUID</button>
                        </li>
                    </ul>
                </ul>
            </ul>
        </CardContent>
    </Card>
);